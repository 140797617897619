import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Collection from 'components/Collection';
import SEO from 'components/seo';
import get from 'lodash.get';
import AuthContext from '../provider/AuthContext';

export default props => {
    let {
        pageContext: { slug },
    } = props;

    let { shopifyCollection, contentfulCollection } = props.data;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulCollection,
                    'seoListing.description.description'
                )}
                title={get(contentfulCollection, 'seoListing.seoTitle')}
                slug={contentfulCollection.handle}
            />
            <AuthContext.Consumer>
                {auth => (
                    <Collection
                        slug={slug}
                        title={contentfulCollection.title}
                        products={shopifyCollection.products}
                        description={get(
                            contentfulCollection,
                            'description.description'
                        )}
                        auth={auth}
                    />
                )}
            </AuthContext.Consumer>
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!, $locale: String) {
        shopifyCollection(handle: { eq: $slug }) {
            ...shopifyCollectionFields
        }
        contentfulCollection(
            handle: { eq: $slug }
            node_locale: { eq: $locale }
        ) {
            title
            handle
            description {
                description
            }
            seoListing {
                ...ContentfulSeoListingFields
            }
        }
    }
`;
